<script setup lang="ts">
</script>
<template>
  <div
    class="our-clients"
    id="our-clients"
  >
    <div class="our-clients-content">
      <h2 class="our-clients-content-title">Наши клиенты</h2>
      <ImageSliderMobile />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.our-clients {
  &-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: get-vw(400px);
    @media (orientation: landscape){
      margin-top: get-vw(200px);
    }

    &-title {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: get-vw(160px);
      text-align: center;
      color: #000000;
      @media (orientation: landscape){
        font-size: get-vw(110px);
      }
    }
  }
}
</style>
